.post {
  @include limit-content-width;

  position: relative;
  z-index: 1;
  background: map-get($colours, 'bg');

  @include bp('medium') {
    padding: 2em 2em 2em 0;
  }

  &__heading {
    @include text-size(2);

    width: clamp(80vw, 2vw, 150%);
    margin: 0;

    @include bp('medium') {
      @include text-size(3);
    }
  }

  &__heading-bg {
    background: map-get($colours, 'bg');

    @include bp('medium') {
      margin: -1px;
      padding: 1px;
      background: map-get($colours, 'bg');
      outline: 0.25em solid map-get($colours, 'bg');
    }
  }

  &__info {
    position: relative;
  }

  &__reading-time {
    @include text-size(0.75);
  }

  &__content {
    @each $n in (1, 2, 3, 4, 5, 6) {
      h#{$n} {
        margin: 2em 0 1em;
      }
    }

    ul {
      padding: 0 0 0 1.25em;
    }

    p {
      margin: 1em 0;
    }
  }
}
