@each $n in (1, 2, 3, 4, 5, 6) {
  h#{$n} {
    @include limit-content-width;

    margin: 0;
  }
}

p {
  margin: 0;
}

a {
  display: inline;
  font-weight: bold;
  color: map-get($colours, 'primary');

  &:visited {
    $primary: map-get($colours, 'primary');
    $secondary: map-get($colours, 'text');

    font-weight: normal;
    color: mix($primary, $secondary, 50%);
  }

  &:focus {
    outline: 2px solid map-get($colours, 'text');
  }
}

code {
  font-family: 'Source Code Pro', monospace;
}

blockquote,
quote {
  display: block;
  margin: 0;
  padding: 0 0 0 1.5em;
  border-left: solid 0.5em map-get($colours, 'code-bg');
}
