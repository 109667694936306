.linked-header {
  display: flex;
  align-items: center;

  &__heading {
    margin: 0 1rem 0 0;
  }

  &__link {
    @include text-size(0.85);

    padding: 0.25em 0.5em;
    background: map-get($colours, 'primary');
    border: none;
    color: map-get($colours, 'text-light');
    text-decoration: none;

    &:visited {
      color: map-get($colours, 'text-light');
      font-weight: bold;
    }
  }
}
