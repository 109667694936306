.tag {
  @include text-size(0.75);

  display: inline-flex;
  align-items: center;
  height: 2em;
  padding: 0 0.75em;
  background: map-get($colours, 'secondary');
  color: map-get($colours, 'text-light');

  &:hover {
    background: map-get($colours, 'primary');
    transition: background 300ms;
  }

  &:last-child {
    margin-right: 0;
  }

  &__link {
    text-decoration: none;
    color: currentColor;

    &:visited {
      color: currentColor;
    }
  }
}
