:root {
  --img: attr(data-img);
}

.post-splash {
  display: none;

  @include bp('medium') {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    height: 100%;
  }

  &__figure {
    position: relative;
    height: 100%;
    margin: 0;
    background: map-get($colours, 'primary');
  }
  
  &__image {    
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
  }

  &__caption {
    @include text-size(0.6);

    padding: 0.175rem 0.25rem;
    text-align: right;
  }
}
