$breakpoints: (
  'small': 20em,
  'medium': 40em,
  'large': 64em,
  'x-large': 90em,
);

@mixin bp($breakpoint) {
  @if map-get($breakpoints, $breakpoint) {
    @media screen and (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @error 'No breakpoint of #{$breakpoint} found within list of known breakpoints';
  }
}

@mixin text-size($multiplier: 1) {
  font-size: $multiplier * 1em;

  @if ($multiplier < 1) {
    $multiplier: 1;
  }

  $line-height: calc(floor(
      $base-line-height * calc($multiplier / round($multiplier)) * 5
    ) / 5);

  line-height: $line-height;
}

@mixin limit-content-width {
  max-width: $content-max-width;
}
