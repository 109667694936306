.contents {
  @include text-size(0.75);

  & ul {
    padding: 0 0 0 1em;
    list-style: none;
  }

  &__items {
    margin: 0;
  }

  &__item {
    &--h3 {
      @include text-size(0.9);
    }

    &--h4 {
      @include text-size(0.8);
    }
  }
}
