.latest-posts {
  display: block;

  @include bp('medium') {
    display: flex;
    margin: 0 -1em;
  }

  &__post {
    margin: 2em 0;
    padding: 0;

    @include bp('medium') {
      flex: 1 0 0%;
      margin: 1em 0;
      padding: 0 1em;
    }
  }
}
