.avatar {
  display: block;
  width: 5em;
  height: 5em;
  background: url('assets/avatar_red.png') center/cover;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.valign > * + * {
  margin-top: 1em;
}

.valign--half > * + * {
  margin-top: 0.5em;
}

@each $n in (2, 3, 4, 5) {
  .valign--#{$n} > * + * {
    margin-top: #{$n}em;
  }
}

.back {
  margin: -0.25em -0.5em;
  padding: 0.25em 0.5em;
  background: none;
  border: 0;
  color: map-get($colours, 'primary');
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: 2px solid map-get($colours, 'text');
  }
}

.highlight {
  @include limit-content-width;

  pre#{&} {
    @include text-size(0.875);

    overflow: auto;
    max-width: 100%;
    padding: 1em;
  }
}

.language-plaintext {
  margin: -0.125em -0.25em;
  padding: 0.125em 0.25em;
  background: map-get($colours, 'code-bg');
  font-weight: bold;
  word-break: break-word;
  color: map-get($colours, 'code');
}
