.intro {
  margin: 0 -#{map-get($gutters, 'small')};
  padding: map-get($gutters, 'small');

  @include bp('medium') {
    margin: 0 -#{map-get($gutters, 'medium')};
    padding: map-get($gutters, 'medium');
  }

  @include bp('large') {
    margin: 0 -#{map-get($gutters, 'large')};
    padding: map-get($gutters, 'large');
  }
}
