*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background: pink;
}

html {
  font-size: 16px;
  line-height: 1.5;
  color: map-get($colours, 'text');
  font-family: 'Noto Sans', sans-serif;
  overflow-x: hidden;

  @include bp('medium') {
    font-size: 18px;
    line-height: 1.4444444444;
  }

  @include bp('large') {
    font-size: 20px;
    line-height: 1.4;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: map-get($colours, 'bg');
}

main {
  flex: 1 0 0%;
}

header[role='banner'] {
  display: flex;
  align-items: center;
  gap: 2em;
  width: fit-content;
}

header[role='banner'],
main,
footer[role='contentinfo'] {
  padding: 0 map-get($gutters, 'small');

  @include bp('medium') {
    padding: 0 map-get($gutters, 'medium');
  }

  @include bp('large') {
    padding: 0 map-get($gutters, 'large');
  }
}

time {
  @include text-size(0.75);

  display: inline-block;
}

hr {
  display: block;
  margin: calc(3em - 1px) 0 !important;
  border: 1px solid #eee;
}

footer[role='contentinfo'] {
  @include text-size(0.75);

  margin-bottom: 1em;
}

abbr {
  cursor: help
}

figure {
  margin: 1em 0;

  img {
    display: block;
    max-width: 100%;
  }

  figcaption {
    @include text-size(0.75);

    margin: 1em 0 0;
    text-align: center;
  }
}