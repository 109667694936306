$base-line-height: 1.5;
$content-max-width: 40em;

$colours: (
  'bg': #fff,
  'text': #2b303b,
  'text-light': #eff1f5,
  'primary': #ff2f51,
  'secondary': #2b303b,
  'code': #95508f,
  'code-bg': #eff1f5,
);

$gutters: (
  'small': 1rem,
  'medium': 2rem,
  'large': 3rem,
);
