.splash {
  margin: 0 -#{map-get($gutters, 'small')};
  padding: calc(#{map-get($gutters, 'small')} * 2) map-get($gutters, 'small');
  background: map-get($colours, 'primary');
  color: map-get($colours, 'text-light');

  @include bp('medium') {
    margin: 0 -#{map-get($gutters, 'medium')};
    padding: calc(#{map-get($gutters, 'medium')} * 2)
      map-get($gutters, 'medium');
  }

  @include bp('large') {
    margin: 0 -#{map-get($gutters, 'large')};
    padding: calc(#{map-get($gutters, 'large')} * 2) map-get($gutters, 'large');
  }

  &__heading {
    @include text-size(3);
  }

  &__blurb {
    @include text-size(1.25);
    @include limit-content-width;
  }

  a {
    color: map-get($colours, 'text-light');

    &:visited {
      $primary: map-get($colours, 'primary');
      $secondary: map-get($colours, 'text-light');

      color: mix($primary, $secondary, 30%);
    }
  }
}
