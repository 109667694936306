.base-list {
  margin: 0;
  list-style: none;
}

.list {
  @extend .base-list;

  padding: 0;
}

.inline-list {
  @extend .base-list;

  display: flex;
  flex-wrap: wrap;
  gap: 0.25em;
  padding: 0;

  &__item {
    flex: 1 0 0%;
  }
}
