.nav {
  &__posts {
    padding: 0;
    list-style: none;
  }

  &__post {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__link {
    flex: 0 1 auto;
    margin: 0 1em 0 0;

    @include bp('medium') {
      flex-basis: 100%;
    }
  }

  &__info {
    @include text-size(0.9);

    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
  }
}
