.snippet {
  @include text-size(0.75);

  display: flex;
  flex-direction: column;
  max-width: 40em;
  height: 100%;

  &__heading {
    margin: 0;
  }

  &__excerpt {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
